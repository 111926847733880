
export default {
  computed: {
    // returnName() {
    //   if (
    //     this.$auth &&
    //     this.$auth.user &&
    //     this.$auth.user.details &&
    //     this.$auth.user.details.displayName
    //   ) {
    //     return `${this.$auth.user.details.displayName}, zahvaljujemo na vašoj narudžbi`
    //   }
    //   return 'Zahvaljujemo na vašoj narudžbi'
    // },
  },
}


import ControllerPost from 'Controllers/ControllerPost'
export default {
  components: {
    ControllerPost,
  },
  computed: {
    // eslint-disable-next-line vue/return-in-computed-property
    returnRoute() {
      if (process.browser) {
        return window.location
      }
    },
  },
}


/* eslint-disable no-useless-return */

import ControllerLogin from 'Controllers/user/ControllerLogin'
import RegisterModal from '@/components/modal/RegisterModal'
import ForgotPasswordModal from '@/components/modal/ForgotPasswordModal'
// import successNoticeModal from '../modal/successNoticeModal'
export default {
  components: {
    ControllerLogin,
    // eslint-disable-next-line vue/no-unused-components
    RegisterModal,
    // eslint-disable-next-line vue/no-unused-components
    ForgotPasswordModal,
    // successNoticeModal
  },
  data() {
    return {
      type: false,
      // notice: false,
      changeTextPass: false,
    }
  },
  // beforeDestroy() {
  //   this.notice = false
  // },
  methods: {
    textPassword() {
      this.changeTextPass = !this.changeTextPass
    },
    callback() {
      // this.notice = true
      if (this.$router.path === '/prijava') {
        this.$router.push('/')
      }
      this.$emit('close')
    },
    changeStatement() {
      this.$modal.show(
        RegisterModal,
        {},
        {
          classes:
            'md:max-w-630w !important xss:w-87pc w-90pc !important my-0 mx-auto left-0 !important register-modal modal-custom',
          adaptive: true,
        }
      )
      this.$emit('close')
    },
    openModalForgotPass() {
      this.$modal.show(
        ForgotPasswordModal,
        {},
        {
          classes:
            'md:max-w-630w !important xss:w-87pc w-90pc !important my-0 mx-auto left-0 !important forgot-password-modal modal-custom',
          adaptive: true,
        }
      )
      this.$emit('close')
    },
  },
}

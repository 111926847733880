import { render, staticRenderFns } from "./ProductList.vue?vue&type=template&id=1d75d9d4&scoped=true&"
import script from "./ProductList.vue?vue&type=script&lang=js&"
export * from "./ProductList.vue?vue&type=script&lang=js&"
import style0 from "./ProductList.vue?vue&type=style&index=0&id=1d75d9d4&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1d75d9d4",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ControllerLayeredNavigation: require('/home/node/app/node_modules/@gauss/cms-core/lib/components/controllers/ControllerLayeredNavigation.js').default,ControllerProductList: require('/home/node/app/node_modules/@gauss/cms-core/lib/components/controllers/ControllerProductList.js').default,ControllerProduct: require('/home/node/app/node_modules/@gauss/cms-core/lib/components/controllers/ControllerProduct.js').default,ControllerFeaturedProducts: require('/home/node/app/node_modules/@gauss/cms-core/lib/components/controllers/ControllerFeaturedProducts.js').default,ControllerCategory: require('/home/node/app/node_modules/@gauss/cms-core/lib/components/controllers/ControllerCategory.js').default,ControllerNewsletter: require('/home/node/app/node_modules/@gauss/cms-core/lib/components/controllers/ControllerNewsletter.js').default})

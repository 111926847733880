
import Product from '@/components/ecommerce/shop/product/product'
import loaderCompoentForProductList from '../modal/loaderCompoentForProductList'
export default {
  components: {
    Product,
    loaderCompoentForProductList,
  },
  data() {
    return {
      total: [],
      items: [],
      numberOfItems: 9,
      loading: true,
      localSavedPage: 1,
      localSavedItem: '',
      pagNums: {
        one: '1',
        two: '2',
        three: '3',
        four: '4',
        five: '5',
      },
    }
  },
  beforeMount() {
    this.localSavedPage = +localStorage.getItem('categoryCurrentPage')
    this.localSavedItem = `[data-slug=${localStorage.getItem('currentItemIn')}]`
    this.loading = true
  },
  mounted() {
    this.$watch(
      () => {
        return this.$refs.searchController.options.page
      },
      (v) => {
        localStorage.setItem('categoryCurrentPage', v)
      }
    )
    setTimeout(() => {
      this.loadToSavedItem()
    }, 1000)
  },
  methods: {
    loadajVise(load) {
      load()
    },
    loadToSavedItem() {
      if (!localStorage.getItem('currentItemIn')) return false
      setTimeout(() => {
        if (document && document.getElementById('LoadMoreBtn')) {
          let curent = 0
          const looper = setInterval(() => {
            document.getElementById('LoadMoreBtn').click()
            if (looper && curent && curent >= this.localSavedPage - 1) {
              setTimeout(() => {
                if (
                  this.localSavedItem.length &&
                  document &&
                  document.querySelector(this.localSavedItem) &&
                  document
                    .querySelector(this.localSavedItem)
                    .getBoundingClientRect().top
                ) {
                  const top =
                    document
                      .querySelector(this.localSavedItem)
                      .getBoundingClientRect().top - 120
                  if (top && window.scrollY) {
                    scroll({
                      top: window.scrollY + top,
                      behavior: 'smooth',
                    })
                    localStorage.setItem('currentItemIn', '')
                  }
                }
              }, 900)
              clearInterval(looper)
            }
            curent++
          }, 800)
        }
      }, 700)
    },
    resetSearchParameter() {
      this.numberOfItems = 8
    },
    // setItems() {
    //   if (
    //     this.$refs.searchController &&
    //     this.$refs.searchController.categories &&
    //     this.$refs.searchController.categories.length &&
    //     this.$refs.searchController.categories[0].items &&
    //     this.$refs.searchController.categories[0].items.length
    //   ) {
    //     this.items = [...this.$refs.searchController.categories[0].items]
    //     if (
    //       this.$refs.searchController.categories[0].items.length <=
    //       this.numberOfItems
    //     ) {
    //       this.items.length =
    //         this.$refs.searchController.categories[0].items.length
    //     } else {
    //       this.items.length = this.numberOfItems
    //     }
    //   } else {
    //     this.items.splice(0, this.items.length)
    //   }
    // },
    loadMore() {
      this.loading = true
    },
  },
}


import ControllerFooter from 'Controllers/ControllerFooter'
import EditorTipTap from 'Editors/EditorTipTap'
import EditorLink from 'Editors/EditorLink'
import EditorImage from 'Editors/EditorImage'
export default {
  components: {
    ControllerFooter,
    EditorTipTap,
    EditorLink,
    EditorImage,
  },
  data() {
    return {
      inView: false,
    }
  },
}

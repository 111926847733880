
export default {
  model: {
    prop: 'selectedButtonValue',
    event: 'change',
  },
  props: {
    id: {
      type: [String, Number],
      default: 'id',
    },
    value: {
      type: [String, Number, Boolean],
      required: true,
    },
    selectedButtonValue: {
      type: [String, Number, Boolean],
      required: true,
    },
    variableType: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    error: {
      type: String,
      default: '',
    },
  },
  computed: {
    localSelectedButtonValue: {
      get() {
        return this.selectedButtonValue
      },
      set() {
        if (this.variableType === 'number') {
          this.$emit('change', parseInt(event.target.value))
        } else if (this.variableType === 'boolean') {
          this.$emit('change', JSON.parse(event.target.value))
        } else {
          this.$emit('change', event.target.value)
        }
      },
    },
  },
}

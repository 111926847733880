export const ControllerBundleCustomization = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/ControllerBundleCustomization.js' /* webpackChunkName: "components/controller-bundle-customization" */).then(c => wrapFunctional(c.default || c))
export const ControllerBundles = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/ControllerBundles.js' /* webpackChunkName: "components/controller-bundles" */).then(c => wrapFunctional(c.default || c))
export const ControllerCalendar = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/ControllerCalendar.js' /* webpackChunkName: "components/controller-calendar" */).then(c => wrapFunctional(c.default || c))
export const ControllerCart = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/ControllerCart.js' /* webpackChunkName: "components/controller-cart" */).then(c => wrapFunctional(c.default || c))
export const ControllerCategory = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/ControllerCategory.js' /* webpackChunkName: "components/controller-category" */).then(c => wrapFunctional(c.default || c))
export const ControllerCommentList = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/ControllerCommentList.js' /* webpackChunkName: "components/controller-comment-list" */).then(c => wrapFunctional(c.default || c))
export const ControllerConfirmEmail = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/ControllerConfirmEmail.js' /* webpackChunkName: "components/controller-confirm-email" */).then(c => wrapFunctional(c.default || c))
export const ControllerContactForm = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/ControllerContactForm.js' /* webpackChunkName: "components/controller-contact-form" */).then(c => wrapFunctional(c.default || c))
export const ControllerCookie = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/ControllerCookie.js' /* webpackChunkName: "components/controller-cookie" */).then(c => wrapFunctional(c.default || c))
export const ControllerCrossSell = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/ControllerCrossSell.js' /* webpackChunkName: "components/controller-cross-sell" */).then(c => wrapFunctional(c.default || c))
export const ControllerCustomForm = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/ControllerCustomForm.js' /* webpackChunkName: "components/controller-custom-form" */).then(c => wrapFunctional(c.default || c))
export const ControllerError = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/ControllerError.js' /* webpackChunkName: "components/controller-error" */).then(c => wrapFunctional(c.default || c))
export const ControllerEvent = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/ControllerEvent.js' /* webpackChunkName: "components/controller-event" */).then(c => wrapFunctional(c.default || c))
export const ControllerFeaturedProducts = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/ControllerFeaturedProducts.js' /* webpackChunkName: "components/controller-featured-products" */).then(c => wrapFunctional(c.default || c))
export const ControllerFooter = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/ControllerFooter.js' /* webpackChunkName: "components/controller-footer" */).then(c => wrapFunctional(c.default || c))
export const ControllerHeader = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/ControllerHeader.js' /* webpackChunkName: "components/controller-header" */).then(c => wrapFunctional(c.default || c))
export const ControllerLayeredNavigation = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/ControllerLayeredNavigation.js' /* webpackChunkName: "components/controller-layered-navigation" */).then(c => wrapFunctional(c.default || c))
export const ControllerNavigationBar = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/ControllerNavigationBar.js' /* webpackChunkName: "components/controller-navigation-bar" */).then(c => wrapFunctional(c.default || c))
export const ControllerNavigationItem = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/ControllerNavigationItem.js' /* webpackChunkName: "components/controller-navigation-item" */).then(c => wrapFunctional(c.default || c))
export const ControllerNewsletter = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/ControllerNewsletter.js' /* webpackChunkName: "components/controller-newsletter" */).then(c => wrapFunctional(c.default || c))
export const ControllerPost = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/ControllerPost.js' /* webpackChunkName: "components/controller-post" */).then(c => wrapFunctional(c.default || c))
export const ControllerPostCategory = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/ControllerPostCategory.js' /* webpackChunkName: "components/controller-post-category" */).then(c => wrapFunctional(c.default || c))
export const ControllerPostCategoryList = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/ControllerPostCategoryList.js' /* webpackChunkName: "components/controller-post-category-list" */).then(c => wrapFunctional(c.default || c))
export const ControllerPostList = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/ControllerPostList.js' /* webpackChunkName: "components/controller-post-list" */).then(c => wrapFunctional(c.default || c))
export const ControllerPostReview = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/ControllerPostReview.js' /* webpackChunkName: "components/controller-post-review" */).then(c => wrapFunctional(c.default || c))
export const ControllerProduct = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/ControllerProduct.js' /* webpackChunkName: "components/controller-product" */).then(c => wrapFunctional(c.default || c))
export const ControllerProductCategories = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/ControllerProductCategories.js' /* webpackChunkName: "components/controller-product-categories" */).then(c => wrapFunctional(c.default || c))
export const ControllerProductConfiguration = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/ControllerProductConfiguration.js' /* webpackChunkName: "components/controller-product-configuration" */).then(c => wrapFunctional(c.default || c))
export const ControllerProductCustomOptions = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/ControllerProductCustomOptions.js' /* webpackChunkName: "components/controller-product-custom-options" */).then(c => wrapFunctional(c.default || c))
export const ControllerProductList = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/ControllerProductList.js' /* webpackChunkName: "components/controller-product-list" */).then(c => wrapFunctional(c.default || c))
export const ControllerProductListContainer = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/ControllerProductListContainer.js' /* webpackChunkName: "components/controller-product-list-container" */).then(c => wrapFunctional(c.default || c))
export const ControllerPublicUpload = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/ControllerPublicUpload.js' /* webpackChunkName: "components/controller-public-upload" */).then(c => wrapFunctional(c.default || c))
export const ControllerRecentlyViewedProducts = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/ControllerRecentlyViewedProducts.js' /* webpackChunkName: "components/controller-recently-viewed-products" */).then(c => wrapFunctional(c.default || c))
export const ControllerRelatedProducts = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/ControllerRelatedProducts.js' /* webpackChunkName: "components/controller-related-products" */).then(c => wrapFunctional(c.default || c))
export const ControllerSearch = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/ControllerSearch.js' /* webpackChunkName: "components/controller-search" */).then(c => wrapFunctional(c.default || c))
export const ControllerUpSell = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/ControllerUpSell.js' /* webpackChunkName: "components/controller-up-sell" */).then(c => wrapFunctional(c.default || c))
export const ControllerUserComment = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/ControllerUserComment.js' /* webpackChunkName: "components/controller-user-comment" */).then(c => wrapFunctional(c.default || c))
export const ControllerAddress = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/checkout/ControllerAddress.js' /* webpackChunkName: "components/controller-address" */).then(c => wrapFunctional(c.default || c))
export const ControllerPayment = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/checkout/ControllerPayment.js' /* webpackChunkName: "components/controller-payment" */).then(c => wrapFunctional(c.default || c))
export const ControllerShipping = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/checkout/ControllerShipping.js' /* webpackChunkName: "components/controller-shipping" */).then(c => wrapFunctional(c.default || c))
export const ControllerChangePassword = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/user/ControllerChangePassword.js' /* webpackChunkName: "components/controller-change-password" */).then(c => wrapFunctional(c.default || c))
export const ControllerForgotPassword = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/user/ControllerForgotPassword.js' /* webpackChunkName: "components/controller-forgot-password" */).then(c => wrapFunctional(c.default || c))
export const ControllerLogin = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/user/ControllerLogin.js' /* webpackChunkName: "components/controller-login" */).then(c => wrapFunctional(c.default || c))
export const ControllerOrderHistory = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/user/ControllerOrderHistory.js' /* webpackChunkName: "components/controller-order-history" */).then(c => wrapFunctional(c.default || c))
export const ControllerRegister = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/user/ControllerRegister.js' /* webpackChunkName: "components/controller-register" */).then(c => wrapFunctional(c.default || c))
export const ControllerResetPassword = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/user/ControllerResetPassword.js' /* webpackChunkName: "components/controller-reset-password" */).then(c => wrapFunctional(c.default || c))
export const ControllerSocialLogin = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/user/ControllerSocialLogin.js' /* webpackChunkName: "components/controller-social-login" */).then(c => wrapFunctional(c.default || c))
export const ControllerSocialLoginButtons = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/user/ControllerSocialLoginButtons.js' /* webpackChunkName: "components/controller-social-login-buttons" */).then(c => wrapFunctional(c.default || c))
export const ControllerUser = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/user/ControllerUser.js' /* webpackChunkName: "components/controller-user" */).then(c => wrapFunctional(c.default || c))
export const ControllerWishlist = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/user/ControllerWishlist.js' /* webpackChunkName: "components/controller-wishlist" */).then(c => wrapFunctional(c.default || c))
export const EditorIcon = () => import('../../node_modules/@gauss/cms-core/lib/components/editors/EditorIcon.js' /* webpackChunkName: "components/editor-icon" */).then(c => wrapFunctional(c.default || c))
export const EditorImage = () => import('../../node_modules/@gauss/cms-core/lib/components/editors/EditorImage.js' /* webpackChunkName: "components/editor-image" */).then(c => wrapFunctional(c.default || c))
export const EditorImageMarkers = () => import('../../node_modules/@gauss/cms-core/lib/components/editors/EditorImageMarkers.js' /* webpackChunkName: "components/editor-image-markers" */).then(c => wrapFunctional(c.default || c))
export const EditorInstagramPostList = () => import('../../node_modules/@gauss/cms-core/lib/components/editors/EditorInstagramPostList.js' /* webpackChunkName: "components/editor-instagram-post-list" */).then(c => wrapFunctional(c.default || c))
export const EditorLink = () => import('../../node_modules/@gauss/cms-core/lib/components/editors/EditorLink.js' /* webpackChunkName: "components/editor-link" */).then(c => wrapFunctional(c.default || c))
export const EditorList = () => import('../../node_modules/@gauss/cms-core/lib/components/editors/EditorList.js' /* webpackChunkName: "components/editor-list" */).then(c => wrapFunctional(c.default || c))
export const EditorListItem = () => import('../../node_modules/@gauss/cms-core/lib/components/editors/EditorListItem.js' /* webpackChunkName: "components/editor-list-item" */).then(c => wrapFunctional(c.default || c))
export const EditorMap = () => import('../../node_modules/@gauss/cms-core/lib/components/editors/EditorMap.js' /* webpackChunkName: "components/editor-map" */).then(c => wrapFunctional(c.default || c))
export const EditorPostList = () => import('../../node_modules/@gauss/cms-core/lib/components/editors/EditorPostList.js' /* webpackChunkName: "components/editor-post-list" */).then(c => wrapFunctional(c.default || c))
export const EditorProductCategoryList = () => import('../../node_modules/@gauss/cms-core/lib/components/editors/EditorProductCategoryList.js' /* webpackChunkName: "components/editor-product-category-list" */).then(c => wrapFunctional(c.default || c))
export const EditorProductList = () => import('../../node_modules/@gauss/cms-core/lib/components/editors/EditorProductList.js' /* webpackChunkName: "components/editor-product-list" */).then(c => wrapFunctional(c.default || c))
export const EditorTipTap = () => import('../../node_modules/@gauss/cms-core/lib/components/editors/EditorTipTap.js' /* webpackChunkName: "components/editor-tip-tap" */).then(c => wrapFunctional(c.default || c))
export const Logo = () => import('../../components/Logo.vue' /* webpackChunkName: "components/logo" */).then(c => wrapFunctional(c.default || c))
export const InView = () => import('../../components/inView.vue' /* webpackChunkName: "components/in-view" */).then(c => wrapFunctional(c.default || c))
export const AnimationsTransitionExpand = () => import('../../components/animations/transition-expand.vue' /* webpackChunkName: "components/animations-transition-expand" */).then(c => wrapFunctional(c.default || c))
export const CmsOverridesCartView = () => import('../../components/cmsOverrides/CartView.vue' /* webpackChunkName: "components/cms-overrides-cart-view" */).then(c => wrapFunctional(c.default || c))
export const CmsOverridesCheckoutSuccessView = () => import('../../components/cmsOverrides/CheckoutSuccessView.vue' /* webpackChunkName: "components/cms-overrides-checkout-success-view" */).then(c => wrapFunctional(c.default || c))
export const CmsOverridesCheckoutView = () => import('../../components/cmsOverrides/CheckoutView.vue' /* webpackChunkName: "components/cms-overrides-checkout-view" */).then(c => wrapFunctional(c.default || c))
export const CmsOverridesCookieElement = () => import('../../components/cmsOverrides/CookieElement.vue' /* webpackChunkName: "components/cms-overrides-cookie-element" */).then(c => wrapFunctional(c.default || c))
export const CmsOverridesErrorView = () => import('../../components/cmsOverrides/ErrorView.vue' /* webpackChunkName: "components/cms-overrides-error-view" */).then(c => wrapFunctional(c.default || c))
export const CmsOverridesFooterElement = () => import('../../components/cmsOverrides/FooterElement.vue' /* webpackChunkName: "components/cms-overrides-footer-element" */).then(c => wrapFunctional(c.default || c))
export const CmsOverridesForgotPasswordView = () => import('../../components/cmsOverrides/ForgotPasswordView.vue' /* webpackChunkName: "components/cms-overrides-forgot-password-view" */).then(c => wrapFunctional(c.default || c))
export const CmsOverridesHeaderElement = () => import('../../components/cmsOverrides/HeaderElement.vue' /* webpackChunkName: "components/cms-overrides-header-element" */).then(c => wrapFunctional(c.default || c))
export const CmsOverridesLoginView = () => import('../../components/cmsOverrides/LoginView.vue' /* webpackChunkName: "components/cms-overrides-login-view" */).then(c => wrapFunctional(c.default || c))
export const CmsOverridesPostList = () => import('../../components/cmsOverrides/PostList.vue' /* webpackChunkName: "components/cms-overrides-post-list" */).then(c => wrapFunctional(c.default || c))
export const CmsOverridesPostView = () => import('../../components/cmsOverrides/PostView.vue' /* webpackChunkName: "components/cms-overrides-post-view" */).then(c => wrapFunctional(c.default || c))
export const CmsOverridesProductList = () => import('../../components/cmsOverrides/ProductList.vue' /* webpackChunkName: "components/cms-overrides-product-list" */).then(c => wrapFunctional(c.default || c))
export const CmsOverridesProductListAll = () => import('../../components/cmsOverrides/ProductListAll.vue' /* webpackChunkName: "components/cms-overrides-product-list-all" */).then(c => wrapFunctional(c.default || c))
export const CmsOverridesProfileView = () => import('../../components/cmsOverrides/ProfileView.vue' /* webpackChunkName: "components/cms-overrides-profile-view" */).then(c => wrapFunctional(c.default || c))
export const CmsOverridesRegisterView = () => import('../../components/cmsOverrides/RegisterView.vue' /* webpackChunkName: "components/cms-overrides-register-view" */).then(c => wrapFunctional(c.default || c))
export const CmsOverridesSearchView = () => import('../../components/cmsOverrides/SearchView.vue' /* webpackChunkName: "components/cms-overrides-search-view" */).then(c => wrapFunctional(c.default || c))
export const CmsOverridesSearchViewBackup = () => import('../../components/cmsOverrides/SearchView__backup.vue' /* webpackChunkName: "components/cms-overrides-search-view-backup" */).then(c => wrapFunctional(c.default || c))
export const CmsOverridesMostPopular = () => import('../../components/cmsOverrides/mostPopular.vue' /* webpackChunkName: "components/cms-overrides-most-popular" */).then(c => wrapFunctional(c.default || c))
export const SectionsAboutUs = () => import('../../components/sections/AboutUs.vue' /* webpackChunkName: "components/sections-about-us" */).then(c => wrapFunctional(c.default || c))
export const SectionsAboutUsTwoImages = () => import('../../components/sections/AboutUsTwoImages.vue' /* webpackChunkName: "components/sections-about-us-two-images" */).then(c => wrapFunctional(c.default || c))
export const SectionsBackgroundBox = () => import('../../components/sections/BackgroundBox.vue' /* webpackChunkName: "components/sections-background-box" */).then(c => wrapFunctional(c.default || c))
export const SectionsBestSellingProductsSection = () => import('../../components/sections/BestSellingProductsSection.vue' /* webpackChunkName: "components/sections-best-selling-products-section" */).then(c => wrapFunctional(c.default || c))
export const SectionsCatalogs = () => import('../../components/sections/Catalogs.vue' /* webpackChunkName: "components/sections-catalogs" */).then(c => wrapFunctional(c.default || c))
export const SectionsInspiration = () => import('../../components/sections/Inspiration.vue' /* webpackChunkName: "components/sections-inspiration" */).then(c => wrapFunctional(c.default || c))
export const SectionsLastViewed = () => import('../../components/sections/LastViewed.vue' /* webpackChunkName: "components/sections-last-viewed" */).then(c => wrapFunctional(c.default || c))
export const SectionsNewOffer = () => import('../../components/sections/NewOffer.vue' /* webpackChunkName: "components/sections-new-offer" */).then(c => wrapFunctional(c.default || c))
export const SectionsNews = () => import('../../components/sections/News.vue' /* webpackChunkName: "components/sections-news" */).then(c => wrapFunctional(c.default || c))
export const SectionsNewsLetter = () => import('../../components/sections/NewsLetter.vue' /* webpackChunkName: "components/sections-news-letter" */).then(c => wrapFunctional(c.default || c))
export const SectionsPolicy = () => import('../../components/sections/Policy.vue' /* webpackChunkName: "components/sections-policy" */).then(c => wrapFunctional(c.default || c))
export const SectionsProductSection = () => import('../../components/sections/ProductSection.vue' /* webpackChunkName: "components/sections-product-section" */).then(c => wrapFunctional(c.default || c))
export const SectionsSponsorSlider = () => import('../../components/sections/SponsorSlider.vue' /* webpackChunkName: "components/sections-sponsor-slider" */).then(c => wrapFunctional(c.default || c))
export const SectionsWeSingleOut = () => import('../../components/sections/WeSingleOut.vue' /* webpackChunkName: "components/sections-we-single-out" */).then(c => wrapFunctional(c.default || c))
export const SectionsAdditionalEquipTub = () => import('../../components/sections/additionalEquipTub.vue' /* webpackChunkName: "components/sections-additional-equip-tub" */).then(c => wrapFunctional(c.default || c))
export const SectionsBuyFromCollection = () => import('../../components/sections/buyFromCollection.vue' /* webpackChunkName: "components/sections-buy-from-collection" */).then(c => wrapFunctional(c.default || c))
export const SectionsChildrenRoom = () => import('../../components/sections/childrenRoom.vue' /* webpackChunkName: "components/sections-children-room" */).then(c => wrapFunctional(c.default || c))
export const SectionsContactUs = () => import('../../components/sections/contactUs.vue' /* webpackChunkName: "components/sections-contact-us" */).then(c => wrapFunctional(c.default || c))
export const SectionsFindBoxFilters = () => import('../../components/sections/findBoxFilters.vue' /* webpackChunkName: "components/sections-find-box-filters" */).then(c => wrapFunctional(c.default || c))
export const SectionsInsatgramSection = () => import('../../components/sections/insatgramSection.vue' /* webpackChunkName: "components/sections-insatgram-section" */).then(c => wrapFunctional(c.default || c))
export const SectionsOurBranches = () => import('../../components/sections/ourBranches.vue' /* webpackChunkName: "components/sections-our-branches" */).then(c => wrapFunctional(c.default || c))
export const SectionsWePresent = () => import('../../components/sections/wePresent.vue' /* webpackChunkName: "components/sections-we-present" */).then(c => wrapFunctional(c.default || c))
export const SectionsYouMightBeInterested = () => import('../../components/sections/youMightBeInterested.vue' /* webpackChunkName: "components/sections-you-might-be-interested" */).then(c => wrapFunctional(c.default || c))
export const UiSpinner = () => import('../../components/ui/Spinner.vue' /* webpackChunkName: "components/ui-spinner" */).then(c => wrapFunctional(c.default || c))
export const UiStarRating = () => import('../../components/ui/StarRating.vue' /* webpackChunkName: "components/ui-star-rating" */).then(c => wrapFunctional(c.default || c))
export const UiSlider = () => import('../../components/ui/slider.vue' /* webpackChunkName: "components/ui-slider" */).then(c => wrapFunctional(c.default || c))
export const ModalForgotPasswordModal = () => import('../../components/modal/ForgotPasswordModal.vue' /* webpackChunkName: "components/modal-forgot-password-modal" */).then(c => wrapFunctional(c.default || c))
export const ModalLoginModal = () => import('../../components/modal/LoginModal.vue' /* webpackChunkName: "components/modal-login-modal" */).then(c => wrapFunctional(c.default || c))
export const ModalRegisterModal = () => import('../../components/modal/RegisterModal.vue' /* webpackChunkName: "components/modal-register-modal" */).then(c => wrapFunctional(c.default || c))
export const ModalLoaderCompoentForProductList = () => import('../../components/modal/loaderCompoentForProductList.vue' /* webpackChunkName: "components/modal-loader-compoent-for-product-list" */).then(c => wrapFunctional(c.default || c))
export const ModalMiniCartPopup = () => import('../../components/modal/miniCartPopup.vue' /* webpackChunkName: "components/modal-mini-cart-popup" */).then(c => wrapFunctional(c.default || c))
export const ModalProductPopupModal = () => import('../../components/modal/productPopupModal.vue' /* webpackChunkName: "components/modal-product-popup-modal" */).then(c => wrapFunctional(c.default || c))
export const ModalSuccessNoticeModal = () => import('../../components/modal/successNoticeModal.vue' /* webpackChunkName: "components/modal-success-notice-modal" */).then(c => wrapFunctional(c.default || c))
export const AnimationsSkeletorElementsSkeletonElementFourCol = () => import('../../components/animations/skeletor-elements/skeleton-element-four-col.vue' /* webpackChunkName: "components/animations-skeletor-elements-skeleton-element-four-col" */).then(c => wrapFunctional(c.default || c))
export const UiInputsInputCheckbox = () => import('../../components/ui/inputs/inputCheckbox.vue' /* webpackChunkName: "components/ui-inputs-input-checkbox" */).then(c => wrapFunctional(c.default || c))
export const UiInputsInputRadio = () => import('../../components/ui/inputs/inputRadio.vue' /* webpackChunkName: "components/ui-inputs-input-radio" */).then(c => wrapFunctional(c.default || c))
export const UiInputsNumberInput = () => import('../../components/ui/inputs/numberInput.vue' /* webpackChunkName: "components/ui-inputs-number-input" */).then(c => wrapFunctional(c.default || c))
export const UiInputsSelectInput = () => import('../../components/ui/inputs/selectInput.vue' /* webpackChunkName: "components/ui-inputs-select-input" */).then(c => wrapFunctional(c.default || c))
export const UiInputsTextInput = () => import('../../components/ui/inputs/textInput.vue' /* webpackChunkName: "components/ui-inputs-text-input" */).then(c => wrapFunctional(c.default || c))
export const EcommerceShopProductOrderProduct = () => import('../../components/ecommerce/shop/product/orderProduct.vue' /* webpackChunkName: "components/ecommerce-shop-product-order-product" */).then(c => wrapFunctional(c.default || c))
export const EcommerceShopProduct = () => import('../../components/ecommerce/shop/product/product.vue' /* webpackChunkName: "components/ecommerce-shop-product" */).then(c => wrapFunctional(c.default || c))
export const EcommerceShopWishListProducts = () => import('../../components/ecommerce/shop/wishList/wishListProducts.vue' /* webpackChunkName: "components/ecommerce-shop-wish-list-products" */).then(c => wrapFunctional(c.default || c))
export const EcommerceShopReletedProducts = () => import('../../components/ecommerce/shop/reletedProducts/ReletedProducts.vue' /* webpackChunkName: "components/ecommerce-shop-releted-products" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}

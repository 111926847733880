
import ControllerCart from 'Controllers/ControllerCart'
import ControllerShipping from 'Controllers/checkout/ControllerShipping'
import ControllerAddress from 'Controllers/checkout/ControllerAddress'
import ControllerPayment from 'Controllers/checkout/ControllerPayment'
import transitionExpand from '@/components/animations/transition-expand'

export default {
  components: {
    ControllerShipping,
    ControllerAddress,
    ControllerPayment,
    transitionExpand,
    ControllerCart,
  },
  data() {
    return {
      showAllProducts: false,
    }
  },
  methods: {
    scrollToSection() {
      setTimeout(() => {
        const activeSection = document.querySelector('.activeStep')
        const offset = activeSection.offsetTop - 144
        if (offset) {
          window.scrollTo({
            top: offset,
            behavior: 'smooth',
          })
        }
      }, 500)
    },
    currencyNameSwitch(price) {
      if (!price) return
      return price.replace('HRK', 'Kn').replace('.', ',')
    },
  },
}


import ControllerPostList from 'Controllers/ControllerPostList'
export default {
  components: {
    ControllerPostList,
  },
  data() {
    return {
      showMe: false,
      haveItem: false,
      selectedCat: null,
      categoriesDropdown: [
        { name: 'All', value: 'All' },
        { name: 'Management', value: 'Management' },
        { name: 'Remote work', value: 'Remote work' },
        { name: 'Technology', value: 'Technology' },
        { name: 'HR', value: 'HR' },
      ],
      categories: {
        all: 'All',
        management: 'Management',
        remoteWork: 'Remote work',
        technology: 'Technology',
        hR: 'HR',
      },
      pagNums: {
        one: '1',
        two: '2',
        three: '3',
        four: '4',
        five: '5',
      },
    }
  },
  methods: {
    fire() {
      this.$toast.info('Still not functional ')
    },
  },
}

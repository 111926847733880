
import inViewport from 'vue-in-viewport-mixin'

export default {
  mixins: [inViewport],
  watch: {
    'inViewport.now': {
      handler(visible) {
        if (visible) {
          this.$emit('load')
        }
      },
    },
  },
}

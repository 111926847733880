
/* eslint-disable prefer-const */
import ControllerCategory from 'Controllers/ControllerCategory'
import ControllerProductList from 'Controllers/ControllerProductList'
import ControllerProduct from 'Controllers/ControllerProduct'
import ControllerLayeredNavigation from 'Controllers/ControllerLayeredNavigation'
import Product from '@/components/ecommerce/shop/product/product'
import clickOutside from 'v-click-outside'
import FourColSkeleton from '@/components/animations/skeletor-elements/skeleton-element-four-col'
import loaderCompoentForProductList from '../modal/loaderCompoentForProductList'
import NumberInput from '../ui/inputs/numberInput'
export default {
  components: {
    ControllerCategory,
    ControllerProductList,
    ControllerProduct,
    ControllerLayeredNavigation,
    Product,
    FourColSkeleton,
    loaderCompoentForProductList,
    NumberInput,
  },
  directives: {
    clickOutside: clickOutside.directive,
  },
  data() {
    return {
      nmbProd: 4,
      clickOutsideConf: {
        handler: this.closeAll,
        middleware: this.clickOutsideMiddleware,
      },
      layeredRef: 'layeredNav',
      mobileLayered: false,
      dropdown: null,
      expand: [],
      layeredNavObject: {},
      filterExpand: false,
      priceDropdown: false,
      mobileMenu: false,
      miniCart: false,
      miniCartMobile: true,
      loading: true,
      localSavedPage: 1,
      localSavedItem: '',
    }
  },
  computed: {
    hasPriceRoutQuery() {
      return Object.prototype.hasOwnProperty.call(
        this.$route.query,
        'retailPrice'
      )
    },
    numberOfProd() {
      return this.nmbProd
    },
  },
  watch: {
    hasPriceRoutQuery(v) {
      if (v) {
        this.disableLoading()
      }
    },
    $route: {
      // eslint-disable-next-line require-await
      async handler() {
        this.mobileMenu = false

        if (process.browser) {
          document.body.classList.remove('modal-html-open')
          document.body.classList.remove('modal-html-open')
        }
      },
    },
    mobileMenu: {
      handler(val) {
        if (val) {
          document.body.classList.add('modal-html-open')
        } else {
          document.body.classList.remove('modal-html-open')
        }
        this.closeAll()
        this.closeMiniCart()
      },
    },
  },
  beforeMount() {
    this.localSavedPage = +localStorage.getItem('categoryCurrentPage')
    this.localSavedItem = `[data-slug=${localStorage.getItem('currentItemIn')}]`
    this.loading = true
  },
  beforeDestroy() {
    if (
      this.$refs.controllerCategoryForProducts &&
      this.$refs.controllerCategoryForProducts.options &&
      this.$refs.controllerCategoryForProducts.options.page
    ) {
      this.$refs.controllerCategoryForProducts.options.page = 1
    }
  },
  mounted() {
    if (this.$refs && this.$refs.layeredNav && this.$refs.layeredNav.form) {
      this.$watch(
        '$refs.layeredNav.form',
        (newvalue, oldvalue) => {
          const keys = Object.keys(newvalue).map((key) => newvalue[key])
          if (keys.every((element) => element === null || element === false)) {
            this.expand = false
          }
        },
        { deep: true }
      )
    }
    this.$watch(
      () => {
        return this.$refs.controllerCategoryForProducts.options.page
      },
      (v) => {
        localStorage.setItem('categoryCurrentPage', v)
      }
    )
    this.$watch(
      () => {
        return (
          this.$refs.controllerCategoryForProducts &&
          this.$refs.controllerCategoryForProducts.products &&
          this.$refs.controllerCategoryForProducts.products.length
        )
      },
      (val) => {
        if (val && val > 0) {
          this.nmbProd = val
        } else {
          this.nmbProd = 4
        }
      }
    )
    this.$watch(
      () => {
        return (
          this.$refs.controllerCategoryForProducts &&
          this.$refs.controllerCategoryForProducts.status &&
          this.$refs.controllerCategoryForProducts.status.pending
        )
      },
      (val) => {
        if (!val) {
          this.disableLoading()
        }
      }
    )
    this.disableLoading()
    this.setFeatherdProductFilterFirst()
    setTimeout(() => {
      this.loadToSavedItem()
    }, 2000)
  },
  methods: {
    loadajVise(load) {
      load()
    },
    disableLoading() {
      setTimeout(() => {
        this.loading = false
      }, 800)
    },
    loadToSavedItem() {
      if (!localStorage.getItem('currentItemIn')) return false
      const categoryName = this.$route.params.slug
      let categoryNameSaved = localStorage.getItem('categoryName')
      if (categoryNameSaved !== categoryName) {
        localStorage.setItem('categoryName', categoryName)
      } else {
        localStorage.setItem('categoryName', categoryNameSaved)
      }
      if (categoryNameSaved === categoryName && this.localSavedPage > 1) {
        setTimeout(() => {
          if (document && document.getElementById('LoadMoreBtn')) {
            let curent = 0
            let looper = setInterval(() => {
              document.getElementById('LoadMoreBtn').click()
              if (looper && curent && curent >= this.localSavedPage - 1) {
                setTimeout(() => {
                  if (
                    this.localSavedItem.length &&
                    document &&
                    document.querySelector(this.localSavedItem) &&
                    document
                      .querySelector(this.localSavedItem)
                      .getBoundingClientRect().top
                  ) {
                    const top =
                      document
                        .querySelector(this.localSavedItem)
                        .getBoundingClientRect().top - 120
                    if (top && window.scrollY) {
                      scroll({
                        top: window.scrollY + top,
                        behavior: 'smooth',
                      })
                      localStorage.setItem('currentItemIn', '')
                    }
                  }
                }, 1200)
                clearInterval(looper)
              }
              curent++
            }, 1000)
          }
        }, 900)
      } else {
        localStorage.setItem(
          'categoryCurrentPage',
          this.$refs.controllerCategoryForProducts.options.page
        )
      }
    },
    closeAll() {
      this.expand.splice(0, this.expand.length)
    },
    clickOutsideMiddleware(e) {
      return true
    },
    closeMiniCartMobile() {
      this.miniCartMobile = false
    },
    openPriceDropdown() {
      this.priceDropdown = !this.priceDropdown
    },
    clearFilters() {
      this.$refs.layeredRef.resetFilters()
    },
    openMobileLayered() {
      this.mobileLayered = !this.mobileLayered
    },
    removeElFromLayeredNav(el) {
      const index = this.expand.indexOf(el)
      if (index > -1) {
        this.expand.splice(index, 1)
      } else {
        return false
      }
    },
    checkIfElementIsInArray(el) {
      const index = this.expand.indexOf(el)
      return index > -1
    },
    closeAllDropdowns(el) {
      const index = this.expand.indexOf(el)
      if (index < -1) {
        this.expand.splice(index, 1)
      } else {
        return false
      }
    },
    openDropdown() {
      this.dropdown = !this.dropdown
    },
    currencyNameSwitch(price) {
      if (!price) return
      return price.replace('HRK', 'Kn')
    },
    setFeatherdProductFilterFirst() {
      if (
        this.$refs.controllerCategoryForProducts &&
        this.$refs.controllerCategoryForProducts.sortOptions &&
        this.$refs.controllerCategoryForProducts.sortOptions.length
      ) {
        const sortOptions = this.$refs.controllerCategoryForProducts.sortOptions
        const index = sortOptions.findIndex((el) => el.name === 'Preporučeno')
        const item = this.$refs.controllerCategoryForProducts.sortOptions[index]
        if (item) {
          item.default = true
          sortOptions.unshift(item)
          sortOptions.splice(index + 1, 1)
          sortOptions.map((el) => {
            if (el.name !== 'Preporučeno' && el.default === true) {
              delete el.default
            }
          })
          this.$set(
            this.$refs.controllerCategoryForProducts,
            'sortOptions',
            sortOptions
          )
          this.$refs.controllerCategoryForProducts.options.sort = 'Preporučeno'
        }
      }
    },
  },
}

import Vue from 'vue'

Vue.mixin({
  methods: {
    $formatDate(date) {
      return new Date(date).toLocaleDateString(this.$i18n.locale, {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      })
    },
    $shareUrl() {
      if (process.browser) {
        return window.location.href
      }
    },
    $currency(v) {
      const { label } = this.$application.currency
      return [Number(v).toFixed(2), label].join(' ')
    },
    $currencyNameSwitch(price) {
      if (!price) return
      return price
        .replace('.', ',')
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')
        .replace('HRK', 'Kn')
    },
  },
})

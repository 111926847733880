
import ControllerAddress from 'Controllers/checkout/ControllerAddress'
import ControllerOrderHistory from 'Controllers/user/ControllerOrderHistory'
import ControllerWishlist from 'Controllers/user/ControllerWishlist'
import ControllerProduct from 'Controllers/ControllerProduct'
import ControllerProductList from 'Controllers/ControllerProductList'
import ControllerChangePassword from 'Controllers/user/ControllerChangePassword'
import WishListProducts from '../ecommerce/shop/wishList/wishListProducts'
import Spinner from '../ui/Spinner'
import OrderProduct from '../ecommerce/shop/product/orderProduct'

export default {
  components: {
    WishListProducts,
    ControllerOrderHistory,
    ControllerWishlist,
    ControllerAddress,
    ControllerProduct,
    ControllerProductList,
    ControllerChangePassword,
    Spinner,
    OrderProduct,
  },
  fetch({ redirect, route }) {
    if (!this.$auth.user) {
      redirect('/prijava')
    }
  },
  data() {
    return {
      type: false,
      narudba: null,
      fullOrderBasePrice: 0,
      tab: 'Pregled računa',
      showProfileEditForm: false,
      historyOrder: false,
      wishListCustom: false,
      orderDetails: false,
      profileViewCustom: false,
      changeTextPassCheck: false,
      orderProductsArray: [],
      productsLoaded: false,
      orderProducts: null,
    }
  },
  computed: {
    previewedItemIssuedFrom() {
      return (
        this.narudba &&
        this.narudba.extendedDetails &&
        this.narudba.extendedDetails.issuedFrom &&
        this.narudba.extendedDetails.issuedFrom.length &&
        this.narudba.extendedDetails.issuedFrom[0]
      )
    },
    previewedItems() {
      return (
        this.narudba &&
        this.narudba.extendedDetails &&
        this.narudba.extendedDetails.items
      )
    },
  },
  watch: {
    tab: {
      handler(v) {
        if (this.$route.query && this.$route.query.tab) {
          this.$router.replace({
            ...this.$router.currentRoute,
            query: {
              search: '' || undefined,
            },
          })
        }
        this.showProfileEditForm = false
        if (v !== 'povijest-narudbi') {
          this.narudba = null
          this.orderDetails = false
        }
      },
      deep: true,
    },
    '$route.query.tab': {
      handler() {
        this.setCurrentTab()
      },
    },
    '$router.path': {
      handler() {
        this.setCurrentTab()
      },
    },
    '$auth.loggedIn': {
      handler() {
        if (!this.$auth.loggedIn) {
          this.logout()
          this.$toast.success('Uspješna odjava!')
        }
      },
    },
    narudba: {
      handler(v) {
        this.productsLoaded = false
        this.orderProductsArray.splice(0, this.orderProductsArray.length)
        this.orderProducts = null
        if (
          v &&
          v.extendedDetails &&
          v.extendedDetails.items &&
          v.extendedDetails.items.length
        ) {
          this.orderProducts = v.extendedDetails.items
          this.productsLoaded = true
        }
        if (!v) {
          this.orderDetails = false
        }
      },
    },
    orderProducts(v) {
      this.fullOrderBasePrice = 0
      if (v && v.length) {
        v.map((e) => {
          this.fullOrderBasePrice += e.finalNetPrice
        })
      }
    },
    orderDetails(v) {
      if (v) {
        this.fetchOrderProducts()
      }
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      })
    },
  },
  mounted() {
    this.profileViewOpenClose()
    this.setCurrentTab()
    this.fetchOrderProducts()
  },
  methods: {
    calculateShippingTax(price, tax) {
      if (!price) return 0
      return price + price * (tax / 100)
    },
    currencyNameSwitch(price) {
      if (!price) return
      return price.replace('HRK', 'Kn')
    },
    async getSingleProduct(id) {
      return await this.$api.product.web.get({
        id,
        storeId: this.$application.store.storeID,
        currency: this.$application.currency.label,
      })
    },
    async fetchOrderProducts() {
      try {
        const data = []
        if (this.orderProducts && this.orderProducts.length) {
          for (const el of this.orderProducts) {
            if (el.item && el.item.id) {
              const res = await this.getSingleProduct(el.item.id)
              if (res) data.push(res)
            }
          }
          if (data && data.length) {
            const result = await Promise.all(data)
            this.orderProductsArray = result
          }
        }
      } catch (e) {
        console.log(e)
      }
    },
    setCurrentTab() {
      if (this.$route.query && this.$route.query.tab) {
        this.tab = this.$route.query.tab
      }
    },
    returnCountryName(countries, currentCountryCode) {
      if (countries && countries.length && currentCountryCode) {
        const country = countries.filter((el) => el.iso === currentCountryCode)
        if (country && country.length) {
          return country[0].name
        } else {
          return 'Država nije postavljena'
        }
      } else {
        return 'Nema države za prikazati'
      }
    },
    orderHistoryOpenClose() {
      this.tab = 'povijest narudbi'
      this.historyOrder = true
      this.wishListCustom = false
      this.profileViewCustom = false
    },
    wishListOpenClose() {
      this.tab = 'lista zelja'
      this.wishListCustom = true
      this.historyOrder = false
      this.profileViewCustom = false
    },
    profileViewOpenClose() {
      this.tab = 'Pregled računa'
      this.profileViewCustom = !this.profileViewCustom
      this.historyOrder = false
      this.wishListCustom = false
    },
    updatePasswordSubmited() {
      alert('Lozinka uspješno promjenjena!')
    },
    changeToNarudbe() {
      this.tab = 'povijest-narudbi'
      this.narudba = null
    },
    logout() {
      this.$auth.logout()
    },
  },
}

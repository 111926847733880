
import ControllerRegister from 'Controllers/user/ControllerRegister'
import LoginModal from '@/components/modal/LoginModal'
// import successNoticeModal from '../modal/successNoticeModal'
export default {
  components: {
    ControllerRegister,
    // eslint-disable-next-line vue/no-unused-components
    LoginModal,
    // successNoticeModal
  },

  data() {
    return {
      privacy: false,
      notice: false,
    }
  },
  beforeDestroy() {
    this.notice = false
  },
  methods: {
    callback() {
      this.notice = true
    },
    changeStatement() {
      this.privacy = !this.privacy
    },
    LoginModalOpen() {
      this.$router.push('/')
      setTimeout(() => {
        this.$modal.show(
          LoginModal,
          {},
          {
            classes:
              'md:max-w-630w !important xss:w-87pc w-90pc !important my-0 mx-auto left-0 !important login-modal modal-custom',
            adaptive: true,
          }
        )
        this.$emit('close')
      }, 1400)
    },
  },
}
